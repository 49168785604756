import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/success/inprogress/linking/spotify',
    name: 'SuccessSpotify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "successSpotify" */ '../views/SuccessView.vue')
  },
  {
    path: '/error/linking/spotify',
    name: 'ErrorSpotify',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "errorSpotify" */ '../views/ErrorView.vue')
  },
  {
    path: "/apple-music/link/:uniqueLinkingCode",
    name: "AppleMusicLink",
    component: () => import(/* webpackChunkName: "apple-music-link" */ '../views/LinkAppleMusic.vue')
  },
  {
    path: "/unauthorized/user-sent/message",
    name: "userUnauthorizedAppleMusicMessage",
    component: () => import(/* webpackChunkName: "user-unauthorized-apple-music-message" */ '../views/userUnauthorizedAppleMusicMessage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

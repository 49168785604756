import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import SimpleAnalytics from "simple-analytics-vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  Vue,
  dsn: "https://9ba65401951c4a6eaf17dd8289d0ba84@o964760.ingest.sentry.io/5915601",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "linktuneninja.ms7m.me", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.use(SimpleAnalytics, { domain: "sa.ms7m.me", skip: process.env.NODE_ENV !== "production" });
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
